{/*********
Date:        Project: 
-----------------------------------------

**********/ }

{/************************** Header Data *************************/ }
const headerData = {
    bgImage: ('../about/about10.jpg'),
    title: "About Me",
    date: "",
    desc: ""
  };
  
  {/************************** Project Data *************************/ }
  
  const aboutMeArray = [];

  for (var i = 1; i <= 3; i++) {
    const obj = {
      src: '../../about/about' + i + '.jpg',
      thumbnail: '../../about/about' + i + '.jpg',
      caption: '',
      category: ["about"],
    }
    aboutMeArray.push(obj);
  }
  
  {/*********************** Return Object Data ***********************/ }
  
  const projectData = {
    imageArray: aboutMeArray,
    projectHeader: headerData
  }
  
  export default projectData;