import { createMuiTheme } from "@material-ui/core/styles";
import styled from 'styled-components'

export const Section = styled.section`
display: ${(props) => props.grid ? "grid" : "flex" };
flex-direction: ${(props) => props.row ? "row" : "column" };
padding: ${(props) => props.nopadding ? "0" : "32px 48px 0" } ;
margin: 0 auto;
max-width: 1040px;
box-sizing: content-box;
position: relative;
overflow: hidden;
grid-template-columns: 1fr 1fr;
@media ${(props) => props.theme.breakpoints.md} {
padding: 24px 48px 0;
flex-direction: column;
}
@media ${(props) => props.theme.breakpoints.sm} {
padding: ${(props) => props.nopadding ? "0" : "16px 16px 0" } ;
width: calc(100vw - 32px);
flex-direction: column;
}
`
export const SectionTitle = styled.h2`
font-weight: 800;
font-size: ${(props) => props.main ? '65px' : '56px'};
line-height: ${(props) => props.main ? '72px' : '56px'};
width: max-content;
max-width: 100%;
background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-bottom: 16px;
padding: ${(props) => props.main ? '58px 0 16px' : '0'};
@media ${props => props.theme.breakpoints.md}{
font-size: ${(props) => props.main ? '56px' : '48px'};
line-height: ${(props) => props.main ? '56px' : '48px'};
margin-bottom: 12px;
padding: ${(props) => props.main ? '40px 0 12px' : '0'};
}
@media ${props => props.theme.breakpoints.sm}{
font-size: 32px;
line-height: 40px;
font-size: ${(props) => props.main ? '28px' : '32px'};
line-height: ${(props) => props.main ? '32px' : '40px'};
margin-bottom: 8px;
padding: ${(props) => props.main ? '16px 0 8px' : '0'};
max-width: 100%;
}
`
export const SectionText = styled.p`
max-width: 800px;
font-size: 24px;
line-height: 40px;
font-weight: 300;
padding-bottom: 3.6rem;
color: rgba(255, 255, 255, 0.5);
@media ${(props) => props.theme.breakpoints.md} {
max-width: 670px;
font-size: 20px;
line-height: 32px;
padding-bottom: 24px;
}
@media ${(props) => props.theme.breakpoints.sm} {
font-size: 16px;
line-height: 24px;
padding-bottom: 16px;
}
`
// Social Icons 
export const SocialIcons = styled.a`
transition: 0.3s ease;
color: white;
border-radius: 50px;
padding: 8px;
&:hover {
background-color: #212d45;
transform: scale(1.2);
cursor: pointer;
}
`


/*const arcBlue = "#0B72B9";
const arcOrange = "#FFBA60";
const arcGrey = "#868686";*/

//const arcBlue="#79B4B7"
const arcBlue="#557174";
const arcOrange = "#F8F0DF";
const arcGrey = "#383838";
const pearlwhite ="#FDF6EC";


export default createMuiTheme({
  palette: {
    common: {
      pearlwhite: pearlwhite,
      blue: arcBlue,
      orange: arcOrange
    },
    primary: {
      main: arcBlue
    },
    secondary: {
      main: arcOrange
    }
  },
  typography: {
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem"
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white"
    },
    h1: {
      fontWeight: 1000,
      fontFamily: "Raleway",
      color: arcBlue
    },
    h2: {
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "2.5rem",
      color: arcBlue,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: "Pacifico",
      fontSize: "2.5rem",
      color: arcBlue
    },
    h4: {
      fontFamily: "Raleway",
      fontSize: "1.75rem",
      color: arcBlue,
      fontWeight: 700
    },
    h5: {
      fontWeight: 300,
      fontFamily: "Raleway",
      color: arcGrey
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Raleway",
      color: arcBlue
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 300,
      color: arcGrey
    },
    subtitle2: {
      color: "white",
      fontWeight: 300,
      fontSize: "1.25rem"
    },
    body1: {
      fontSize: "1.25rem",
      color: arcGrey,
      fontWeight: 300
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      color: arcGrey
    },
    learnButton: {
      borderColor: arcBlue,
      borderWidth: 2,
      textTransform: "none",
      color: arcBlue,
      borderRadius: 50,
      fontFamily: "Roboto",
      fontWeight: "bold"
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: pearlwhite,
        fontSize: "1rem"
      }
    },
    MuiInput: {
      root: {
        color: arcGrey,
        fontWeight: 300
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${arcBlue}`
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${arcBlue}`
        }
      }
    }
  },
  zIndex: {
    card: 15000
  }
});
