import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./ui/Theme";
import Header from "./ui/Header";
import Footer from "./ui/Footer";
import { handelRightClick } from "./ui/AppUtility";
import LandingPage from "./LandingPage";
import Contact from "./Contact";
import FeaturedPortfolioConfig from "./configs/FeaturedPortfolioConfig";
import AboutMeConfig from "./configs/AboutMeConfig";
import PortfolioConfig from "./configs/PortfolioConfig";
import ProjectContainer from "./projects/ProjectContainer";
import ScrollAnimation from 'react-animate-on-scroll';

function App() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <div id="section1">
        <ScrollAnimation delay={0} animateIn="fadeIn" animateOnce={false}>
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <Grid key="section2">
                <LandingPage
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <ProjectContainer
                    showFilter={true}
                    imageArray={FeaturedPortfolioConfig.imageArray}
                    headerInfo={FeaturedPortfolioConfig.projectHeader}
                    renderType="feature"
                    // SHOW SMALLER MARGINS FOR GALLERY
                // style={"container containerMarginTopFeature"}
                />
                </ScrollAnimation>
              </Grid>
            )}
          />
          <Route
            exact
            path="/portfolio"
            render={props => (
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <ProjectContainer
                  showFilter={true}
                  imageArray={FeaturedPortfolioConfig.imageArray}
                  headerInfo={PortfolioConfig.projectHeader}
                  renderType="feature"
                  // SHOW SMALLER MARGINS FOR GALLERY
              // style={"container containerMarginTopFeature"}
              />
          </ScrollAnimation>
            )}
          />
          <Route
            exact
            path="/customsoftware"
            render={props => (
              <LandingPage
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />
          <Route
            exact
            path="/mobileapps"
            render={props => (
              <LandingPage
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />
          <Route
            exact
            path="/websites"
            render={props => (
              <LandingPage
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />
          <Route
            exact
            path="/revolution"
            render={props => (
              <LandingPage
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />
          <Route
            exact
            path="/about"
            render={props => (
              <ProjectContainer
                  showFilter={true}
                  imageArray={AboutMeConfig.imageArray}
                  headerInfo={AboutMeConfig.projectHeader}
                  renderType="about"
                  // SHOW SMALLER MARGINS FOR GALLERY
              // style={"container containerMarginTopFeature"}
              />
            )}
          />
          <Route
            exact
            path="/contact"
            render={props => (
              <Contact
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />
          <Route
            exact
            path="/estimate"
            render={props => (
              <LandingPage
                {...props}
                setValue={setValue}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          />
        </Switch>
        </ScrollAnimation>
        </div>
        <Footer setValue={setValue} setSelectedIndex={setSelectedIndex} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
