import React, { Component } from 'react';
//import { Container, Row, Col, Mask, Fa, View, Button } from 'mdbreact';
import { Box, Button, Card, CardContent, CardActions, CardActionArea, CardMedia, Grid, Hidden, Typography } from '@material-ui/core';
import { BorderColor } from '@material-ui/icons';
import ImageShadow from 'react-image-shadow';
import 'react-image-shadow/assets/index.css';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import ComputerIcon from '@material-ui/icons/Computer';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import './css/app.css'

//import ReactGA from 'react-ga';
// ReactGA.pageview(window.location.href);

const AboutMe = () => {

    window.scroll({
        top: 0,
        behavior: "smooth"
    });    
    // console.log("*******Lazy Load*******");
    /*ReactGA.event({
        category: 'LandedOn: /AboutPage',
        action: 'Landed On',
    });*/
    console.log("About me Page");
    return (

        <Box class="aboutme">
        <Grid container justify="center" spacing={2} >
            <Grid item xs={12} justify="center" className="view stripe">
                <Typography variant='h4'>
                <p>
                    Welcome to my portfolio<br/>
                    I am a photographer, avid reader, and a technology leader. I was born and raised in Hong Kong and spent the later half of my life in Vancouver Canada. A city dweller who loves to travel and find new adventures along the way. 
                </p>
                </Typography>
            </Grid>

            <Grid item md={12} sx={{py:30}}>
                <Grid container justify="center" spacing={4} >
                    <Grid item xs={12} md={4} justify="center" className="view stripe" sx={{overflow: 'hidden', flexBasis:"55%", maxWidth:"55%"}}>
                        <ImageShadow
                            width="325px" 
                            src="../about/about3.jpg"
                            loading="lazy"
                            shadowRadius="2"
                            shadowBlur="10"
                        />
                    </Grid>
                    <Grid item xs={12} md={8} justify="center" className="view stripe" sx={{px:40, flexGrow:1}}>
                        <Box sx={{ display: 'inline-flex', gridTemplateColumns: '2,1fr'}}>
                            <Box sx={{width:"40px"}}>
                            <CameraAltOutlinedIcon style={{ color: 'black', fontSize: 30 }}/>
                            </Box>
                            <Box>
                            <Typography variant="h4" sx={{width:"250px", fontWeight:600}}>
                                Photographer
                            </Typography> 
                            </Box>
                        </Box>
                        <Typography variant="caption">
                        <p>
                            A hobby I started since I was 15. My first camera was a Yashica Electro 35 GSN, a family camera that I could get my hands on at the time. I shoot just about anything from travel, landscapes, lifestyle, astrophotography and portraits.
                            <br/><br/>
                            Photography is a form of art as well as a way of storytelling. A picture worth a 1000 words. I always admire how photojournalists use images to document people's life.  Their pictures not only inspire how I take my own but also inspire me how I view the world.
                            </p>
                        </Typography>
                        <br/>
                        <Typography variant="h6">
                            My Work Horse
                        </Typography>
                        <hr/>
                        <Typography variant="caption" sx={{color:"black"}}>
                            <li>Sony Alpha 7 III</li>
                            <li>Sony FE 50mm f1.8</li>
                            <li>Sony FE 28-70mm F3.5-5.6</li>
                            <li>Nikon D610</li>
                            <li>Nikkor 50mm F1.4</li>
                            <li>Nikkor 24-70mm F2.8</li>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sx={{my: "50px"}}>
                <hr class="hrspacer"></hr>
            </Grid>
            <Grid item md={12} sx={{py:30}}>
                <Grid container justify="center" spacing={4} >
                    <Grid item xs={12} md={8} justify="center" className="stripe" sx={{overflow: 'hidden', flexBasis:"55%", maxWidth:"55%"}}>
                        <Box sx={{ display: 'inline-flex', gridTemplateColumns: '2,1fr'}}>
                            <Box sx={{width:"40px"}}>
                                <ComputerIcon style={{ color: 'black', fontSize: 30 }}/>
                            </Box>
                            <Box>
                            <Typography variant="h4" sx={{width:"250px", fontWeight:600}}>
                            Software Engineering Manager
                            </Typography> 
                            </Box>
                        </Box>                         
                        <Typography variant="caption">
                            <p>
                            I currently serve as a manager of software engineering teams. I have a computer science background with over 22 years of experience progressed from a programmer to an Engineering Manager. I am passionate about technology, building products and scaling teams to bridge business/people needs. 
                            <br/><br/>
                            I built this web site from scratch to show case my work and to keep my hands dirty with latest technology                          
                            </p>
                        </Typography>
                        <br/>
                        <Typography variant="h6">
                            Technology 
                        </Typography>
                        <hr/>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Typography variant="caption" sx={{color:"black"}}>                            
                                    <li>React.js</li>
                                    <li>Redux</li>
                                    <li>Material-ui</li>
                                    <li>Next.js</li>
                                    <li>ES9</li>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" sx={{color:"black"}}>                            
                                    <li>Java</li>
                                    <li>Redis</li>
                                    <li>Hadoop</li>
                                    <li>Data Warehouse</li>
                                    <li>Python</li>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} justify="center" className="view stripe" sx={{px:40, flexGrow:1}}>
                        <ImageShadow
                            width="325px" 
                            src="../about/about5.jpg"
                            loading="lazy"
                            shadowRadius="2"
                            shadowBlur="10"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} >
                <hr class="hrspacer"></hr>
            </Grid>
            <Grid item md={12} >
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4} justify="center" className="view stripe">
                        <ImageShadow
                            width="325px" 
                            src="../about/about9.jpg"
                            loading="lazy"
                            shadowRadius="2"
                            shadowBlur="10"
                        />
                    </Grid>
                    <Grid item xs={8} justify="center" className="view stripe">
                    <Box sx={{ display: 'inline-flex', gridTemplateColumns: '2,1fr'}}>
                            <Box sx={{width:"40px"}}>
                            <MapOutlinedIcon style={{ color: 'black', fontSize: 30 }}/>
                            </Box>
                            <Box>
                            <Typography variant="h4" sx={{width:"250px", fontWeight:600}}>
                                Desultory Traveller
                            </Typography> 
                            </Box>
                        </Box>
                        <Typography variant="caption">
                        <p>
                            <br/><br/>
                            Do you know there are a total of 195 countries in the world today? I'd love to explore all of them one day. But  I can only do it slowly at this stage. Of course, I will document my travels with cameras along the way. Stay tuned.
                            </p>
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>   
        </Box>
    
    );
}
export default AboutMe;