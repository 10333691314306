import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import footerAdornment from "../../assets/Footer Adornment.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import { SocialIcons } from './Theme'
import { AiFillGithub, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.common.blue,
    width: "100%",
    zIndex: 1302,
    position: "relative"
  },
  adornment: {
    width: "25em",
    verticalAlign: "bottom",
    [theme.breakpoints.down("md")]: {
      width: "21em"
    },
    [theme.breakpoints.down("xs")]: {
      width: "15em"
    }
  },
  mainContainer: {
    position: "absolute"
  },
  link: {
    color: "grey",
    fontFamily: "Arial",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none"
  },
  gridItem: {
    margin: "3em"
  },
  icon: {
    height: "4em",
    width: "4em",
    [theme.breakpoints.down("xs")]: {
      height: "2.5em",
      width: "2.5em"
    }
  },
  socialContainer: {
    position: "absolute",
    marginTop: "-6em",
    right: "1.5em",
    [theme.breakpoints.down("xs")]: {
      right: "0.6em"
    }
  }
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer id="footer_anchor"  >
      <Grid container style={{ backgroundColor:"#525E75", marginTop: "-3.5em !important", flexDirection: 'row' }} className="page-footer font-small pt-4 mt-4 pagefooter-margin" >

        <Grid item style={{ textAlign: "right", flex: 1}}>
            <Toolbar disableGutters >
              <SocialIcons href="https://github.com/alanchangcw"  target="_blank" rel="noopener noreferrer">
                  <AiFillGithub size="3rem" />
                </SocialIcons>
                <SocialIcons href="https://instagram.com/alan.chang.pd"  target="_blank" rel="noopener noreferrer">
                  <AiFillInstagram size="3rem" />
                </SocialIcons>
                <SocialIcons href="https://www.linkedin.com/in/alan-chang-952a896/"  target="_blank" rel="noopener noreferrer">
                  <AiFillLinkedin size="3rem" />
              </SocialIcons>
            </Toolbar>
        </Grid>
        <Grid item style={{ textAlign: "right", paddingRight:"30px"}}>
                <Typography variant="h5" style={{color: "white"}}><p> Built with ReactJS</p></Typography>
        </Grid>
    </Grid>
</footer>
  );
}
