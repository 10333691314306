{/*********
Date:        Project: 
-----------------------------------------

**********/ }

{/************************** Header Data *************************/ }
const headerData = {
    bgImage: ('../street/street15.jpg'),
    title: "Portfolio",
    date: "",
    desc: ""
  };
  
  {/************************** Project Data *************************/ }
  
  const aboutMeArray = [];

  for (var i = 1; i <= 53; i++) {
    const obj = {
      src: '../../street/street' + i + '.jpg',
      thumbnail: '../../street/street' + i + '.jpg',
      caption: '',
      category: ["street"],
    }
    aboutMeArray.push(obj);
  }
  
  {/*********************** Return Object Data ***********************/ }
  
  const projectData = {
    imageArray: aboutMeArray,
    projectHeader: headerData
  }
  
  export default projectData;