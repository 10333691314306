import shuffle from './shuffle';

{/*********
ProjectName: 2018_Europe
Date: 2018_Europe
Location: 
Notes:
**********/}

{/************************** Header Data *************************/ }
const headerData = {
  bgImage: '../travel/travel23.jpg',
  title: "Featured",
  date: "",
  desc: "",
  style: "featured-header-mobile"
};

{/************************** Gallery Data *************************/ }
let imageArray = [];


{/************************** Travel Data *************************/ }
const travelArray = [];
const featTravel = [3,6,9,10,17,20,37];

for (var i = 1; i <= 38; i++) {
  const obj = {
    src: '../../travel/travel' + i + '.jpg',
    thumbnail: '../../thumb/traveltn' + i + '.jpg',
    caption: '',
    category: ["travel"],
  }
  if(featTravel.includes(i)){
    obj['category'].push('*')
  }
  travelArray.push(obj);
}

{/************************** People Data *************************/ }
const peopleArray = [];
const featPeople = [2,4,5,7,8,25,29,31,35,44,57];

for (var i = 1; i <= 67; i++) {
  const obj = {
    src: '../../people/people' + i + '.jpg',
    thumbnail: '../../thumb/peopletn' + i + '.jpg',
    caption: '',
    category: ["ppl"],
  }
  if(featPeople.includes(i)){
    obj['category'].push('*')
  }
  peopleArray.push(obj);
}
{/************************** Film Data *************************/ }
const filmArray = [];
const featFilm = [3,4,12,13,14];

for (var i = 1; i <= 14; i++) {
  const obj = {
    src: '../../film/film' + i + '.jpg',
    thumbnail: '../../thumb/filmtn' + i + '.jpg',
    caption: '',
    category: ["flm"],
  }
  if(featFilm.includes(i)){
    obj['category'].push('*')
  }
  filmArray.push(obj);
}

{/************************** Urban Data *************************/ }
const urbanArray = [];
const featUrban = [3,7,9,11,13,15,16,18,20,24,28,35,36,45,48,53,54];
for (var i = 1; i <= 54; i++) {
  const obj = {
    src: '../../street/street' + i + '.jpg',
    thumbnail: '../../thumb/streettn' + i + '.jpg',
    caption: '',
    category: ["urb"],
  }
  if(featUrban.includes(i)){
    obj['category'].push('*')
  }
  urbanArray.push(obj);
}



{/*********************** Return Object Data ***********************/ }
imageArray = [...shuffle(travelArray), ...shuffle(peopleArray), ...shuffle(filmArray), ...shuffle(urbanArray)];
// console.log("arrayIMages: " + JSON.stringify(imageArray))

const projectData = {
  imageArray: (imageArray),
  projectHeader: headerData
}

export default projectData;