import React, { Component } from 'react';
import './css/app.css'
import ResponsiveGallery from './ResponsiveGallery';

const GalleryContainer = (props) =>{

    return (
      <div>
        <ResponsiveGallery showFilter={props.showFilter} images={props.imageArray}/> 
      </div>
    );
}

export default GalleryContainer;