import React, { Component } from 'react';
//import Lightbox from 'react-images';
//import { LightBox } from 'react-lightbox-pack'; 
//import "react-lightbox-pack/dist/index.css";
import FsLightbox from 'fslightbox-react';
import PropTypes from 'prop-types';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ScrollAnimation from 'react-animate-on-scroll';
//import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarNav, NavItem, NavLink, Mask, View } from 'mdbreact';
import './css/app.css'
// import Animated from "react-animated-css"
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import LazyLoad from 'react-lazy-load';
import shuffle from './configs/shuffle'

/*import ReactGA from 'react-ga';

function trackGA(action) {
  console.log("clicked Action: ", action);
  
  ReactGA.event({
    category: 'Clicked: ' + action,
    action: 'Clicked Portolio Image/Filter' + action,
  });
}*/


class ResponsiveGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      imageArray: [],
      showFilter: false,
      toggler:false,
      sIndex:0,
    };

    //Lightbox
    /*this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this); */


    //Image Control
    /*this.renderGallery = this.renderGallery.bind(this);
    this.filterImage = this.filterImage.bind(this);

    this.cursorStyle = { cursor: "pointer" };
    this.renderFilter = this.renderFilter.bind(this);
    this.showThumbnailBanner = this.showThumbnailBanner.bind(this);*/

  }
  //************************ LightBox *************************//
  /*
  openLightbox(index, event) {
    //trackGA("clicked_image: "+index)
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    // console.log("length: " + this.state.imageArray.length)
    // console.log("current image: " + this.state.currentImage)
    //Stops from going to next index when reached end of array
    if ((this.state.currentImage + 1) < this.state.imageArray.length) {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    }
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return;
    this.gotoNext();
  }

  // Show Banner only if on features page
  showThumbnailBanner(obj) {
    if (this.props.location.pathname === '/') {
      return (
        <div className="stripe light">
          <div>
            <p>{obj.caption}</p>
            <p><i className="fa fa-calendar date" aria-hidden="true"> October 5th, 2018</i></p>
          </div>
        </div>
      );
    }
  }*/
  //END************************ LightBox *************************//


  
  lightBoxHandler(toggle, sIndex){
    console.log ("lightBoxHandler Toggle: " + toggle +" sIndex: " + sIndex);
    this.setState({
      toggler: toggle,
      sIndex: sIndex,
    })
  };

  renderGallery(images) {
    console.log("*****Lazy Load Responsive Gallery******");
    if (!images) {
      return;
    }
    const gallery = images.map((obj, i) => {

      console.log("image array: ", obj.id + " " +obj.category);

      return (  
        <LazyLoad key={i}>
          <ScrollAnimation key={i} delay={i * 15} animateIn="fadeIn" animateOnce={true} >
            <div className={`view overlay zoom ` + obj.category} data-category={obj.category}>
              <img alt=""
                className=""
                // onClick={(e) => this.openLightbox(i, e)}
                src={obj.thumbnail}
                style={{ width: "100%", height: "auto", display: "block" }}
                //onClick={() => trackGA("image: " + i)}
                onClick={() => {
                  if (obj.category)
                  this.lightBoxHandler(!this.state.toggler, i);}}
              />
              <div style={this.cursorStyle} className="mask flex-center rgba-white-light" onClick={(e) => this.openLightbox(i, e)}>
                {/* Show Banner only on Feature Page */}
                {/* {this.showThumbnailBanner(obj)} */}
              </div>
            </div>            
          </ScrollAnimation>
        </LazyLoad>


      );
    });
    return (

    <div>
      {/*This allows grid view on mobile*/}
      <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 750: 2, 900: 3 }} >
        <Masonry gutter="1.5px">
          {gallery}
        </Masonry>
      </ResponsiveMasonry>
    </div>
    );
  }

  filterImage(filter) {
    let imagesCopy = this.props.images;
    //Track clicks
    //trackGA(filter);

    //Filter Images
    let newArray = imagesCopy.filter(function (img) {
      let searchValue = img.category; //Array of Categories
      return searchValue.includes(filter);
    });

    if (filter === "*") {
      newArray = shuffle(newArray);
    }
    this.setState({ imageArray: newArray });

    console.log("filtered imagearray" + this.state.imageArray.category + " " + this.state.imageArray.id);
  }

  renderFilter(showFilter) {
    const tabStyle = { cursor: "pointer", fontFamily: "Raleway", fontWeight:"800", color:"#557174" };

    if (showFilter) {
      return (
        <Tabs id="Tab" defaultTab="one" className="GalleryContainer">
          <TabList className="TabList" style={{ border: 'none', margin: '0em 0 1em 0em' }}>
            <Tab style={tabStyle} tabFor="one" onClick={() => this.filterImage("*")}>Featured</Tab>
            <Tab style={tabStyle} tabFor="two" onClick={() => this.filterImage("travel")}>Travel</Tab>
            <Tab style={tabStyle} tabFor="three" onClick={() => this.filterImage("ppl")}>People</Tab>
            <Tab style={tabStyle} tabFor="four" onClick={() => this.filterImage("urb")}>Urban & Street</Tab>
            <Tab style={tabStyle} tabFor="five" onClick={() => this.filterImage("flm")}>Film</Tab> 
            {/* <Tab style={tabStyle} tabFor="six" onClick={() => this.filterImage("concert")}>Concert</Tab> */}
            {/* <Tab style={this.cursorStyle} tabFor="six" onClick={() => this.filterImage("all")}>All</Tab> */}
            {/*}
            <Tab tabFor="sevon">
              <Dropdown>
                <DropdownToggle className="brand colorBlackLink" nav caret>Projects</DropdownToggle>
                <DropdownMenu>
                  <NavbarNav>
                    <NavItem className="nav-format">
      <NavLink className="brand nav-format" to="/projects">All Projects</NavLink>*/}
                      {/* <NavLink className="brand nav-format" to="/laurenlychee"> > Lauren Lychee</NavLink> */}
                    {/*</NavItem>
                  </NavbarNav>
                </DropdownMenu>
              </Dropdown>
    </Tab>*/}
          </TabList>
        </Tabs>
      );
    }
  }

  //Will Set State before initial Render
  componentWillMount() {
    //For Featured Gallery
    if (this.props.location.pathname === '/') {
      const featArray = this.props.images.filter(function (img) {
        let searchValue = img.category; //Array of Categories
        return searchValue.includes('*');
      });
      this.setState({
        imageArray: shuffle(featArray),
        showFilter: this.props.showFilter
      });
    }
    // For Project Galleries
    else {
      this.setState({
        imageArray: this.props.images,
        showFilter: this.props.showFilter
      });
    }

  }

  //Scroll To Hide Header
  componentDidMount() {
    if (!this.state.showFilter) {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
      console.log("RESPONSIVE GALLERY: window did mount");
    }
  }


  render() {
    const cursorStyle = { cursor: "pointer" };


    const newImageArray = [];
    
    for (var i = 0; i < this.state.imageArray.length; i++) {
      
      const obj = this.state.imageArray[i];
      console.log("imageArraySrc["+i+"] " + obj['src']);
      newImageArray.push(obj['src']);
    }
    



    return (
      <div className="content page-section spad center">

        {this.renderFilter(this.state.showFilter)}
        {/* <LazyLoad offsetTop={3000}> */}
        {this.renderGallery(this.state.imageArray)}
        {/* </LazyLoad> */}
        <FsLightbox
              toggler={this.state.toggler}
              sources={[...newImageArray]}
              slide={this.state.sIndex+1}
        />
      </div>
    );
  }
}

ResponsiveGallery.propTypes = {
  images: PropTypes.array,
  showThumbnails: PropTypes.bool,
};

export default withRouter(ResponsiveGallery);