import React from "react";

import ProjectHeader from './ProjectHeader'
import GalleryContainer from  '../GalleryContainer'
import AboutMe from '../AboutMe';


const RenderBody = (props) => {
    if (props.renderType === "gallery" || props.renderType === "feature") {
        return <GalleryContainer
        style={props.style}
        showFilter={props.showFilter}
        imageArray={props.imageArray}
    />
    }
    /*else if (renderType === "allprojects") {
        return <All_Projects
            imageArray={this.state.imageArray}
        />
    } */
    else if (props.renderType === "about") {
        return <AboutMe/>;
    }
    else {
        return <span>No Pictures</span>
    }
}

const ProjectContainer = (props) => {

    const renderType = props.renderType;
    
    return(
        <div id="projectcontainer" className="">
            <ProjectHeader
                {...props.headerInfo}
            />
            {RenderBody(props)}
        </div>        
    );
}

export default ProjectContainer;