import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { Parallax, Paper } from "react-parallax";
import projectData from '../configs/FeaturedPortfolioConfig';
import '../css/app.css'
import '../css/project.css';

export default function ProjectHeader (props) {

    console.log("HeaderInfo", props.headerInfo);
    console.log("ImageArray", props.imageArray);

    
    const bgImage= props.bgImage;
    const title= props.title;
    const date= props.date;
    const desc= props.desc;
    const style= props.style;
    

    return (
        <div>
          <Parallax bgImage={bgImage} strength={300} className={`parallax-mobile-view ` + style }>
            <div className="headerbg">
            </div>
          </Parallax>
          <div style={{
              position: "relative",
              top: "70px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderColor: "white",
              border: "0px",
          }}>
          <div id="headerbox" className="rounded z-depth-2">
            <Card body className="text-center Card rounded z-depth-1" elevation={24} >
              <Typography variant="h1" className="headerTitle display-4" ><strong>{title}</strong></Typography>
              {/* <h1 className="headerTitle display-4" ><strong>{title}</strong></h1> */}
              {/* <hr className="" style={{border: "1px solid grey "}} /> */}
              {/* <h5 className="blue-text headerDate"><i className="fa fa-calendar date" aria-hidden="true"> {date} </i></h5> */}
              <Typography variant="h5" className="secondary headerDate">{date} </Typography>
              <Typography variant="body1" className="grey-text headerDesc"> {desc}</Typography>
            </Card>
          </div>
        </div>
        </div>
  
      );
}